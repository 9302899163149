import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { Text } from 'common/UI'
import { RichtextStoryblok, TextLinkStoryblok } from 'common/types'
import { CMSLink } from 'modules/shared'
import { RendererFunction, SubContentProps } from 'modules/shared/CMSRichText'

const getListItems = (content: RichtextStoryblok) => {
  const elements = content?.content?.map((cont, index) => {
    const innerElements = cont.content?.map((innerCont, index) => {
      return (
        <React.Fragment key={index}>{renderContent(innerCont)}</React.Fragment>
      )
    })

    return <ListItem key={index}>{innerElements}</ListItem>
  })

  return elements
}

const BulletList = ({ content }: SubContentProps): JSX.Element => {
  const elements = getListItems(content)

  if (!elements?.length) return <br />

  return <Ul css={{ listStyle: 'initial', paddingLeft: '1rem' }}>{elements}</Ul>
}

export const Paragraph = ({ content }: SubContentProps): JSX.Element => {
  const elements = content?.content?.reduce<JSX.Element[]>(
    (accumulator, current, index) => {
      const marks = current?.marks?.map((mark) => mark.type)

      const isLink = marks?.includes('link')
      const italic = marks?.includes('italic')
      const bold = marks?.includes('bold')
      const underline = marks?.includes('underline')
      const strike = marks?.includes('strike')

      const cssObj: CSSProperties = {
        fontStyle: italic ? 'italic' : 'inherit',
        fontWeight: bold ? 400 : 'inherit',
        textDecoration: `${underline ? 'underline' : ''} ${
          strike ? 'line-through' : ''
        }`,
      }

      if (current.type === 'hard_break') {
        return [...accumulator, <br key={index} />]
      }

      if (isLink) {
        const link = current.marks?.filter(({ type }) => type === 'link')[0]
          .attrs

        if (link) {
          /**
           * We have many editors who linked to external addresses without the protocol.
           * This hack adds https to all hrefs starting with www.
           */
          const linkHref = link.story
            ? link.story.full_slug
            : link.href?.startsWith('www.')
            ? `https://${link.href}`
            : link.href

          let href: TextLinkStoryblok['link'] = {
            linktype: 'url',
            cached_url: linkHref,
          }

          if (link.linktype && link.linktype === 'email') {
            href = {
              linktype: 'email',
              email: linkHref,
            }
          }

          if (link.linktype && link.linktype === 'story') {
            href = {
              linktype: 'story',
              cached_url: linkHref,
            }
          }

          cssObj.textDecoration = 'underline'

          return [
            ...accumulator,
            <CMSLink
              key={index}
              href={href}
              linkStyle="text"
              target={link.target || undefined}
            >
              <Text
                as="span"
                variant="sixteen"
                color="accent.secondary"
                style={cssObj}
                css={{ opacity: '80%' }}
              >
                {current.text}
              </Text>
            </CMSLink>,
          ]
        }
        return [...accumulator]
      }

      return [
        ...accumulator,
        <Text as="span" variant="sixteen" style={cssObj} key={index}>
          {current.text}
        </Text>,
      ]
    },
    []
  )

  if (!elements?.length) return <></>

  return (
    <Text as="p" variant="sixteen" css={{ opacity: '80%' }}>
      {elements}
    </Text>
  )
}

export const renderContent = (
  content: RichtextStoryblok,
  renderer: RendererFunction = (_, renderDefault) => renderDefault()
) => {
  switch (content.type) {
    case 'paragraph':
      return renderer('paragraph', () => <Paragraph content={content} />)
    case 'bullet_list':
      return renderer('bullet_list', () => <BulletList content={content} />)
    default:
      throw new Error(`Received unexpected content type: ${content.type}`)
  }
}

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  padding: 1.25rem 0 1.25rem 0;

  & & {
    margin: 0;
  }
`

const ListItem = styled.li`
  & > p {
    padding-bottom: 0;
  }

  &::marker {
    color: ${({ theme }) => theme.colors.foreground.default};
  }
`
